import { UsersState } from 'stores/models/redux-state.models'
import * as actions from '../actions'

const initState: UsersState = {
  isLoading: false,
  user: undefined,
  actions: []
}

const user = (state = initState, action: any) => {
  switch (action.type) {
    case actions.SET_AUTH_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    
    case actions.GET_USER_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action.payload,
      }
    default:
      return state
  }
}

export default user
