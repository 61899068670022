import { DeployLogsState } from 'stores/models'
import DeployLogModel from 'stores/models/deploy-log.models'
import * as actions from '../actions'

const initState: DeployLogsState = {
  isLoading: false,
  deployLogs: [],
  total: 0,
  deployLog: undefined,
}

const deployLog = (state: DeployLogsState = initState, action: any) => {
  switch (action.type) {
    case actions.GET_DEPLOY_LOGS_REQUEST:
      return {
        ...state,
        isLoading: state.deployLogs?.length === 0,
      }
    case actions.GET_DEPLOY_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deployLogs: action.payload?.rows.map((p: any) => new DeployLogModel(p)) ?? [],
        total: action.payload?.total
      }
    case actions.GET_DEPLOY_LOGS_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case actions.SET_CURRENT_DEPLOY_LOG:
      return {
        ...state,
        deployLog: action.payload
      }

    default:
      return state
  }
}

export default deployLog
