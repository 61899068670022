import moment from 'moment'
import DeployLogModel, { DeployLogSourceText } from 'stores/models/deploy-log.models'
import useStyles from './styles'
import { useNavigate } from 'react-router-dom'

import * as actions from 'stores/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'stores/models'
import { UserActions } from 'stores/models/user.models'
import { Interweave } from 'interweave'
import { useTranslation } from 'react-i18next'

interface Props {
  item: DeployLogModel
  i18n: any
}

const DeployLogItem = (props: Props) => {
  const { item } = props
  const styles = useStyles()
  const navigate: any = useNavigate()
  const dispatch = useDispatch()
  const { t, i18n }: any = useTranslation()
  const content = ['en', 'en_US'].includes(i18n.language) ? item?.contentEN : item?.contentNO


  const { user, userActions } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    userActions: state.users.actions
  }))

  const onOpenEdit = () => {
    dispatch({ type: actions.SET_CURRENT_DEPLOY_LOG, payload: item })
    navigate(`/logs/edit/${item?.id}`)
  }

  return (
    <div className={styles.rowItem}>
      <p className={styles.rowItemDate}>{moment(item?.deployedAt).format('DD.MM.YYYY')}</p>
      <div className={styles.rowItemContent}>
        <p className={styles.rowItemSource}>{DeployLogSourceText[item.source] ?? 'Oryton'}{item?.version ? ` (v${item.version})` : ''}</p>
        <Interweave content={content} />
      </div>
      {user && userActions.includes(UserActions.DeployLogEdit) && (
        <button className={styles.editButton} onClick={onOpenEdit}>
          {t('home.editLog')}
        </button>
      )}
    </div>
  )
}

export default DeployLogItem
