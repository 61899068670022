import { useState } from "react"
import { useTranslation } from 'react-i18next'
import CrytoJs from "crypto-js"

import { Wrapper } from "components"
import useStyles from './styles'
import { envConfigs } from "configs"

const PasswordAuthentication = (props: any) => {
  const [state, setState] = useState({
    password: '',
    isWrongPassword: false
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))


  const styles = useStyles()
  const { t }: any = useTranslation()

  const onChangePassword = (event: any) =>{
    handleStateChange({ password: event.target.value })
  } 

  const onAuthenticate = async () => {
    const hash = envConfigs.PASSWORD_PROTECTION
    const tokenSecret = envConfigs.TOKEN_SECRET

    const bytes = CrytoJs.AES.decrypt(hash, tokenSecret)

    if (bytes.sigBytes !== 0) {
      const decode = bytes.toString(CrytoJs.enc.Utf8)
      if (state.password === decode) {
        props.onAuthenticated()
      } else {
        handleStateChange({ isWrongPassword: true })
      }
    }
  }

  const isValid = state.password
  return (
    <Wrapper className={styles.container}>
      <h2 className={styles.title}>{t('home.addDeployLog')}</h2>
      <div>
        <p className={styles.txtNote}>{t('home.forDeveloper')}</p>
        <p className={styles.label}>{t('home.enterPasswordProtect')}</p>
        <div>
          <input className={styles.inputPassword} type="password" value={state.password} onChange={onChangePassword} />
        </div>
        <p className={styles.txtNote}>{state.isWrongPassword ? 'Incorrect password.' : ''}</p>
        <button disabled={!state.password} onClick={onAuthenticate} className={isValid ? styles.buttonSave : styles.buttonSaveDisabled}>
          {t('common.confirm')}
        </button>
      </div>
    </Wrapper>
  )
}

export default PasswordAuthentication
