import { envConfigs } from 'configs'
import gateway, { APIMethod } from './gateway'

const ACCOUNT_API_SERVER = envConfigs.ACCOUNT_API

interface CheckLoginParams {
  token?: string
}

export const checkLogin = async (params: CheckLoginParams) => {
  return gateway.send(APIMethod.POST, `${ACCOUNT_API_SERVER}/api/check-login`, params)
}

export const getUserByToken = async (token: string) => {
  return gateway.send(APIMethod.GET, `${ACCOUNT_API_SERVER}/users/token/${token}`)
}

export const getUserActions = async () => {
  return gateway.send(APIMethod.GET, `${ACCOUNT_API_SERVER}/api/action/deploy`)
}
