const { location: { host } } = window

const production = {
  ENV: 'production',
  SERVER_URL: 'https://api-deploy-log.oryton.no',
  ACCOUNT_WEB: 'https://account.oryton.no',
  ACCOUNT_API: 'https://api-account.oryton.no',
  TOKEN_SECRET: 'deploy-logs',
  PASSWORD_PROTECTION: 'U2FsdGVkX19P1ZX0ehuwkp74b6xeF+QjgLeYcx3CwuXMKaoAwUKTUO/FRG1AwLTW'
}

const uat = {
  ENV: 'uat',
  SERVER_URL: 'https://dev-api.deploy-log.oryton.vn',
  ACCOUNT_WEB: 'https://account.oryton.vn',
  ACCOUNT_API: 'https://api-account.oryton.vn',
  TOKEN_SECRET: 'deploy-logs',
  PASSWORD_PROTECTION: 'U2FsdGVkX19P1ZX0ehuwkp74b6xeF+QjgLeYcx3CwuXMKaoAwUKTUO/FRG1AwLTW'
}

const dev = {
  ENV: 'dev',
  SERVER_URL: 'http://localhost:4301',
  ACCOUNT_WEB: 'https://account.oryton.vn',
  ACCOUNT_API: 'https://api-account.oryton.vn',
  TOKEN_SECRET: 'deploy-logs',
  PASSWORD_PROTECTION: 'U2FsdGVkX19P1ZX0ehuwkp74b6xeF+QjgLeYcx3CwuXMKaoAwUKTUO/FRG1AwLTW'
}

export const envConfigs = host.indexOf('oryton.no') >= 0 ? production : host.indexOf('localhost') >= 0 ? dev : uat
