import { envConfigs } from 'configs';
import { DeployLogSource } from 'stores/models/deploy-log.models';
import gateway, { APIMethod } from './gateway'

const API_SERVER = envConfigs.SERVER_URL

interface GetDeployLogsParams {
  skip?: number;
  limit?: number;
  source?: string;
}

interface UpdateDeployLogParams {
  content_en: string
  content_no: string
}

interface AddDeployLogParams {
  source: DeployLogSource
  content_en: string
  content_no: string
  deployed_at: string
  version?: string
}

export const getDeployLogs = async (params: GetDeployLogsParams) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/deploy-logs`, params)
}

export const getDeployLogById = async (logId: number) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/deploy-logs/${logId}`)
}

export const updateDeployLog = async (logId: number, params: UpdateDeployLogParams) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/deploy-logs/${logId}`, params)
}


export const addDeployLog = async (params: AddDeployLogParams) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/deploy-logs`, params)
}
