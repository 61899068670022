import { Routes, Route } from 'react-router-dom'
import CrytoJs from 'crypto-js'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'

import './App.css'
import './locale'
import Home from './containers/home'
import NotFound from './containers/not-found'
import EditLog from './containers/edit-log'
import AddLog from './containers/add-log'
import { localStorageUtils, stringUtils } from 'utils'
import { LocalStorageValueTypes } from 'utils/localStorage'
import * as actions from 'stores/actions'
import { UsersAPI } from 'stores/apis'
import UserModel from 'stores/models/user.models'
import gateway from 'stores/apis/gateway'
import { envConfigs } from 'configs'

function App () {
  const dispatch = useDispatch()

  const saveUser = (data: any) => {
    const tokenSecret = envConfigs.TOKEN_SECRET
    const roleId = JSON.stringify(data.RoleId)
    data.RoleId = CrytoJs.AES.encrypt(roleId, tokenSecret).toString()

    gateway.init(data.AccessToken)
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) })
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))

    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, data.Language)
    i18next.changeLanguage(data?.Language)
  }

  const authLogin = async (token?: string) => {
    const response: any = await UsersAPI.checkLogin({ token })
    const { data } = response

    if (data?.isSuccess) {
      saveUser(data?.data)
      dispatch({ type: actions.GET_USER_ACTIONS_REQUEST })
    }

    // wait for saving user
    setTimeout(() => {
      dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
    }, 1000)
    return data?.isSuccess
  }

  const getUserLogin = () => {
    const userLocal = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserData)
    if (userLocal) return JSON.parse(userLocal)
    return null
  }

  const checkAuthorized = async () => {
    dispatch({ type: actions.SET_AUTH_LOADING, payload: true })
    const _user = getUserLogin()

    if (_user) {
      const isSuccess = await authLogin(_user?.AccessToken)
      if (!isSuccess) onLogout()
    } else {
      const params = stringUtils.getJsonFromUrl(window.location.search)
      const { token } = params

      if (token) {
        getUserByToken(token)
      } else {
        window.location.href = `${envConfigs.ACCOUNT_WEB}/login?redirect-url=${encodeURIComponent(window.location.href)}`
      }
    }
  }

  const onLogout = async () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
    window.location.href = `${envConfigs.ACCOUNT_WEB}/logout.html?redirect-url=${encodeURIComponent(window.location.href)}`
  }

  const getUserByToken = async (token: string) => {
    const isSuccess: boolean = await authLogin(token)

    if (isSuccess) {
      // remove token from url
      const regexToken = new RegExp(`[?|&]+token=${token}`)
      const redirectUrl = window.location.href.replace(regexToken, '')
      window.location.href = redirectUrl
    } else {
      onLogout()
    }
  }

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    if (lang) i18next.changeLanguage(lang)
    checkAuthorized()
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logs/edit/:logId" element={<EditLog />} />
      <Route path="/logs/add" element={<AddLog />} />
      <Route path="/logout.html" element={<AddLog />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
