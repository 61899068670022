import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    flexDirection: 'column',
    width: '70%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px'
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px'
  },

  title: {
    fontSize: '30px'
  },

  select: {
    padding: '5px 10px',
    borderColor: '#dfe3e9',
    borderRadius: '5px'
  },

  filters: {
    paddingBottom: '10px'
  },

  filterTab: {
    backgroundColor: '#dfe3e9',
    borderStyle: 'none',
    padding: '5px 15px',
    marginRight: '10px',
    borderRadius: '20px',
    marginTop: '10px'
  },

  filterTabActive: {
    borderStyle: 'none',
    padding: '5px 15px',
    marginRight: '10px',
    borderRadius: '20px',
    backgroundColor: '#2196f3',
    marginTop: '10px'
  },

  filterTabText: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#7c7c7c',
    textTransform: 'uppercase',
  },

  filterTabTextActive: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#fff',
    textTransform: 'uppercase',
  },

  btnAddLog: {
    border: '0px solid transparent',
    backgroundColor: 'white',
    color: '#fd7e14',
    textTransform: 'uppercase',
  },

  langList: {
    paddingLeft: '0px',
    boxShadow: '0 10px 16px 0 rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 9%)',
    borderRadius: '5px',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'white',
    listStyleType: 'none',
  },

  lang: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: '5px',
    border: '1px solid #dfe3e9',
    width: '100px',
  },

  langRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '5px 10px',
    borderWidth: '0px'
  },

  flag: {
    width: '18px',
    height: '18px',
    marginRight: '7px'
  },

  txtEmpty: {
    fontSize: '16px',
    color: '#999',
    paddingTop: '20px',
    fontWeight: '500',
  }
})

export default useStyles
