import { useTranslation } from "react-i18next"
import useStyles from "./styles"


const NotFound = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.code}>404</h1>
      <h1 className={styles.text}>{t('home.notFound')}</h1>
    </div>
  )
}

export default NotFound
