import { envConfigs } from 'configs'
import CrytoJs from 'crypto-js'
import jwtDecode from 'jwt-decode'

export enum UserRole {
  User = 1,
  Admin = 2,
  Accountant = 3,
  AccountantLimit = 4,
  VNAccountant = 5,
  Recruitment = 6,
  VietNamAdmin = 7,
  CEO = 14,
  HREcoFinansAS = 15,
  HROryton = 16,
  Developer = 17,
  AdminRecruitment = 18
}

export enum UserStatus {
  Active = 1,
  UnActive = 0,
  Block = -1,
  UnConfirm = -2
}

export enum UserActions {
  DeployLogAdd = 'DeployLog-Add',
  DeployLogEdit = 'DeployLog-Edit',
  DeployLogList = 'DeployLog-List',
}

export default class UserModel {
  public id!: number
  public email!: string
  public phone!: string
  public firstName!: string
  public lastName!: string
  public role!: UserRole
  public status!: UserStatus
  public language!: string
  public refreshToken!: string
  public accessToken!: string
  public actions!: string[]

  constructor(data: any) {
    if (data) {
      this.id = data.Id
      this.email = data.Email
      this.phone = data.Phone
      this.firstName = data.FirstName
      this.lastName = data.LastName
      this.status = data.Status
      this.language = data.Language
      this.refreshToken = data.RefreshToken
      this.accessToken = data.AccessToken

      const tokenSecret = envConfigs.TOKEN_SECRET
      const bytes = CrytoJs.AES.decrypt(data.RoleId, tokenSecret)
      if (bytes.sigBytes !== 0) {
        const decode = JSON.parse(bytes.toString(CrytoJs.enc.Utf8))
        this.role = decode
      }

      const actions: any = data.Actions ? jwtDecode(data.Actions) : []

      if (actions.length > 0) {
        this.actions = actions.filter((a: string) =>  a.indexOf('DeployLog') > -1)
      }
    }
  }
}