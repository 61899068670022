import { combineReducers } from 'redux'
import test from './test.reducers'
import deployLogs from './deploy-log.reducers'
import users from './user.reducers'

export default combineReducers({
  test,
  deployLogs,
  users,
})
