import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './lang/en_US'
import nnNO from './lang/nn_NO'

i18next.use(initReactI18next).init({
  lng: 'nn_NO',
  debug: true,
  resources: {
    en_US: {
      translation: enUS
    },
    en: {
      translation: enUS
    },
    nn_NO: {
      translation: nnNO
    },
    no: {
      translation: nnNO
    }
  }
})

export default i18next
