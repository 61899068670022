import { CSSProperties } from 'react'
import HashLoader from 'react-spinners/HashLoader'
import useStyles from "./styles"

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
};

export const Loading = () => {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      <HashLoader loading={true} cssOverride={override} size={80} color="#2196f3" />
    </div>
  )
}
