export default {
  common: {
    ok: 'OK',
    cancel: 'Avbryt',
    confirm: 'Bekreft',
    delete: 'Slett',
    allow: 'Tillatt',
    clear: 'Slett',
    setup: 'Setup',
    update: 'Update',
    enable: 'Slå på',
    save: 'Lagre',
    logout: 'Logg ut'
  },
  wrapper: {
    logoutConfirm: 'Er du sikker på at du vil logge ut?',
    switchAccount: 'Bytt konto',
  },
  home: {
    title: 'Oppdateringshistorikk',
    norway: 'Norsk',
    english: 'Engelsk',
    loading: 'Laster inn',
    editLog: 'Rediger logg',
    addLog: 'Legg til logg',
    editDeployLog: 'Rediger oppdateringsloggen',
    all: 'Alle',
    addDeployLog: 'Legg til oppdateringsloggen',
    forDeveloper: '*Note: Denne siden kun for utviklere',
    enterPasswordProtect: 'Vennligst skriv inn passord for å få tilgang til denne siden',
    notFound: 'Finnes ikke',
    notAuthorizedPage: 'Dessverre har du ikke tillatelse til å få tilgang til denne siden',
    noDeployLog: 'Ingen oppdateringshistorikk',
  }
}
