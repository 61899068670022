import { useSelector } from "react-redux"

import { ReduxState } from "stores/models"
import { UserActions } from "stores/models/user.models"
import { Loading } from 'components/loading'
import useStyles from "./styles"
import Header from './components/header'
import Modal from './components/modal'
import { modalRef } from "utils/refs"
import { useTranslation } from "react-i18next"
import { localStorageUtils } from "utils"
import { envConfigs } from "configs"

interface WrapperProps {
  children: React.ReactNode
  className?: string,
  loadingDisabled?: boolean
}

export const Wrapper = (props: WrapperProps) => {
  const { children, className, loadingDisabled } = props
  const { isLoading, user, actions } = useSelector((state: ReduxState) => ({
    isLoading: state.users.isLoading,
    user: state.users.user,
    actions: state.users.actions
  }))
  const styles = useStyles()
  const { t } = useTranslation()

  if (isLoading && !loadingDisabled) return <Loading />

  const isEditLogPage = window.location.href.indexOf('logs/edit') > -1
  const isAddLogPage = window.location.href.indexOf('logs/add') > -1

  const logout = () => {
    localStorageUtils.clear()
    window.location.href = `${envConfigs.ACCOUNT_WEB}/logout.html?redirect-url=${encodeURIComponent(window.location.href)}`
  }

  if (!user || (isEditLogPage && !actions.includes(UserActions.DeployLogEdit)) || (isAddLogPage && !actions.includes(UserActions.DeployLogAdd)) || !actions.includes(UserActions.DeployLogList)) return (
    <div className={styles.notAuthorizedWrapper}>
      <h1 className={styles.notAuthorizedNum}>403</h1>
      <h1 className={styles.notAuthorizedText}>{t('home.notAuthorizedPage')}</h1>

      <button onClick={logout} className={styles.btnYes}>
        {t('wrapper.switchAccount')}
      </button>
    </div>
  )

  return (
    <div className={className}>
      <Header />
      {children}
      <Modal ref={modalRef} />
    </div>
  )
}
