import { envConfigs } from 'configs';
import gateway, { APIMethod } from './gateway'

const API_SERVER = envConfigs.ACCOUNT_API

interface ChangeLanguageParams {
  UserId: number
  Language: string
}

export const changeLanguage = async (params: ChangeLanguageParams) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/api/language`, params)
}
