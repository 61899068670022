export default {
  common: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    delete: 'Delete',
    allow: 'Allow',
    clear: 'Clear',
    setup: 'Setup',
    update: 'Update',
    enable: 'Enable',
    save: 'Save',
    logout: 'Logout'
  },
  wrapper: {
    logoutConfirm: 'Are you sure you want to logout?',
    switchAccount: 'Switch account',
  },
  home: {
    title: 'Deploy Logs',
    norway: 'Norway',
    english: 'English',
    loading: 'Loading',
    editLog: 'Edit log',
    addLog: 'Add log',
    editDeployLog: 'Edit Deploy Log',
    all: 'All',
    addDeployLog: 'Add Deploy Log',
    forDeveloper: '*Note: This page only for developers',
    enterPasswordProtect: 'Please enter password-protect to access this page',
    notFound: 'Not found',
    notAuthorizedPage: 'Unfortunately, You don\'t have permission to access this page',
    noDeployLog: 'No Deploy Log',
  }
}
