import { CSSProperties, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { DeployLogsAPI } from "stores/apis"
import { ReduxState } from "stores/models"
import { Wrapper, TextEditor, Loading } from "components"
import useStyles from './styles'
import { HashLoader } from "react-spinners"

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
}

const EditLog = () => {
  const [state, setState] = useState({
    log: undefined,
    contentEN: '',
    contentNO: '',
    isSaving: false,
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))
  const navigate: any = useNavigate()
  const { user } = useSelector((state: ReduxState) => ({
    user: state.users.user,
  }))


  const { logId } = useParams()
  const styles = useStyles()
  const { t }: any = useTranslation()

  const deployLog = useSelector((state: ReduxState) => state.deployLogs.deployLog)
  const initData = (data: any) => {
    handleStateChange({
      log: data,
      contentEN: data.contentEN ?? data.content_en,
      contentNO: data.contentNO ?? data.content_no,
    })
  }

  const onGetLog = async () => {
    if (deployLog && deployLog?.id === Number(logId)) {
      initData(deployLog)
    } else {
      const response = await DeployLogsAPI.getDeployLogById(Number(logId))
      if (response?.data?.isSuccess) {
        initData(response.data.data)
      }
    }
  }
  
  useEffect(() => {
    if (user) onGetLog()
  }, [user])

  const onChangeContentEN = (contentEN: string) => handleStateChange({ contentEN })
  const onChangeContentNO = (contentNO: string) => handleStateChange({ contentNO })

  const onSaveLog = async () => {
    handleStateChange({ isSaving: true })

    const response = await DeployLogsAPI.updateDeployLog(Number(logId), {
      content_en: state.contentEN,
      content_no: state.contentNO
    })

    handleStateChange({ isSaving: false })
    if (response?.status === 200) {
      navigate(-1)
    }
  }

  if (!state.log) return <Loading />
  const isValid = !state.isSaving && state.contentEN && state.contentNO

  return (
    <Wrapper className={styles.container}>
      <h2 className={styles.title}>{t('home.editDeployLog')}</h2>
      <div>
        <label className={styles.label}>
          {t('home.english')}:
        </label>
        
        <TextEditor data={state?.contentEN} onChange={onChangeContentEN} />

        <label className={styles.label}>
          {t('home.norway')}:
        </label>
        
        <TextEditor data={state?.contentNO} onChange={onChangeContentNO} />

        <button disabled={state.isSaving || !state.contentEN || !state.contentNO} onClick={onSaveLog} className={isValid ? styles.buttonSave : styles.buttonSaveDisabled}>
          {state.isSaving ? (
            <HashLoader loading={true} cssOverride={override} size={20} color="#fff" />
          ) : <p>{t('common.save')}</p>}
        </button>
      </div>
    </Wrapper>
  )
}

export default EditLog
