import axios, { AxiosResponse, Method } from 'axios'
import { envConfigs } from 'configs'

export enum APIMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const parseFormData = (data: any) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    const value = data[key]
    formData.append(`${key}`, value)
  })

  return formData
}

export class APIGateway {
  private token: string | null = ''

  async init (token: string | null) {
    this.token = token
  }

  private async call (method: Method, url: string, params: any = {}, _headers: any = {}): Promise<AxiosResponse | undefined> {
    let headers: any = {
      'X-Powered-By': 'Express',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Content-Type': 'application/json; charset=utf-8'
    }

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`
    }

    headers = Object.assign(headers, _headers)

    try {
      const response: any = await axios
        .create({ timeout: 300000, headers })
        .request({
          method,
          url,
          data: method !== 'GET' && params,
          params: method === 'GET' && params
        })
        .catch(function (error) {
          return error.response
        })

      if (envConfigs.ENV !== 'production') {
        console.log(`[API][${response?.data?.isSuccess ? 'SUCCESS' : 'FAILED'}] - ${method} ${url}`)
      }

      return response
    } catch (e) {
      if (envConfigs.ENV !== 'production') {
        console.log(e)
      }
    }
  }

  async send (method: Method, url: string, params?: any, headers?: any) {
    return await this.call(method, url, params, headers)
  }
}

const gateway = new APIGateway()
export default gateway
