import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  rowItem: {
    margin: '10px 0',
    paddingBottom: '20px'
  },

  rowItemDate: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '10px'
  },

  rowItemContent: {
    paddingLeft: '10px'
  },

  rowItemSource: {
    color: '#2196F3',
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  rowItemText: {
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '26px',
    whiteSpace: 'break-spaces'
  },

  editButton: {
    marginLeft: '10px',
    border: '1px solid #2196F3',
    backgroundColor: 'white',
    borderRadius: '5px',
    color: '#2196F3',
    fontSize: '12px'
  }
})

export default useStyles
