import { envConfigs } from "configs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ReduxState } from "stores/models"
import { localStorageUtils } from "utils"
import { modalRef } from "utils/refs"
import useStyles from "./styles"

const LogoutModalContent = ({ t, styles }: any) => {
  const onCloseModal = () => modalRef?.current?.close()

  const logout = () => {
    localStorageUtils.clear()
    window.location.href = `${envConfigs.ACCOUNT_WEB}/logout.html?redirect-url=${encodeURIComponent(window.location.href)}`
  }

  return (
    <div>
      <h6>{t('wrapper.logoutConfirm')}</h6>
      <div className={styles.logoutBtns}>
        <button onClick={onCloseModal} className={styles.btnNo}>
          {t('common.cancel')}
        </button>
        <button onClick={logout} className={styles.btnYes}>
          {t('common.logout')}
        </button>
      </div>
    </div>
  )
}

const Header = () => {
  const styles = useStyles()
  const { t }: any = useTranslation()
  const user = useSelector((state: ReduxState) => state.users.user)

  if (!user) return null

  const onOpenLogoutModal = () => {
    modalRef?.current?.open(<LogoutModalContent t={t} styles={styles} />)
  }

  return (
    <div className={styles.container}>
      <p className={styles.txtName}>Hi, {user?.firstName}</p>
      <button onClick={onOpenLogoutModal} className={styles.btnLogout}>{t('common.logout')}</button>
    </div>
  )
}

export default Header
