
export enum DeployLogSource {
  Oryton = 1,
  BilagskyWeb = 2,
  BilagskyMobile = 3,
  TravelWeb = 4,
  OrytonMobile = 5,
  OrytonAccount = 6,
  InvoiceWeb = 7,
  OrytonSalary = 8,
  OrytonAccounting = 9,
}

export const DeployLogSourceText: any = {
  [DeployLogSource.Oryton]: 'Oryton',
  [DeployLogSource.OrytonAccount]: 'Oryton Account',
  [DeployLogSource.OrytonSalary]: 'Oryton Salary',
  [DeployLogSource.OrytonAccounting]: 'Oryton Accounting',
  [DeployLogSource.BilagskyWeb]: 'Bilagsky Web',
  [DeployLogSource.BilagskyMobile]: 'Bilagsky Mobile',
  [DeployLogSource.TravelWeb]: 'Travel Web',
  [DeployLogSource.OrytonMobile]: 'Oryton Mobile',
  [DeployLogSource.InvoiceWeb]: 'Invoice Web',
}

export const DeployLogSources = [
  DeployLogSource.Oryton,
  DeployLogSource.OrytonAccount,
  DeployLogSource.OrytonSalary,
  DeployLogSource.OrytonAccounting,
  DeployLogSource.BilagskyWeb,
  DeployLogSource.BilagskyMobile,
  DeployLogSource.InvoiceWeb,
  DeployLogSource.TravelWeb,
  DeployLogSource.OrytonMobile
]

export default class DeployLogModel {
  public id!: number
  public source!: DeployLogSource
  public contentEN!: string
  public contentNO!: string
  public version!: string
  public deployedAt!: string
  public createdAt!: string
  public updatedAt!: string

  constructor (data: any) {
    if (data) {
      this.id = data.id
      this.source = data.source
      this.contentEN = data.content_en
      this.contentNO = data.content_no
      this.version = data.version
      this.deployedAt = data.deployed_at
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
    }
  }
}
