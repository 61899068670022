
import { takeLatest, put, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import * as actions from 'stores/actions'
import { DeployLogsAPI } from 'stores/apis'

export function * getDeployLogsWorker (action: any) {
  try {
    const response: AxiosResponse | undefined = yield call(DeployLogsAPI.getDeployLogs, action.payload)

    if (response?.data?.data) {
      yield put({ type: actions.GET_DEPLOY_LOGS_SUCCESS, payload: response.data.data })
    } else {
      yield put({ type: actions.GET_DEPLOY_LOGS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_DEPLOY_LOGS_FAILED })
  }
}

function * getDeployLogsWatcher () {
  yield takeLatest(actions.GET_DEPLOY_LOGS_REQUEST, getDeployLogsWorker)
}

export default getDeployLogsWatcher
