import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useSelector } from "react-redux"
import '@ckeditor/ckeditor5-build-classic/build/translations/no'

import { ReduxState } from "stores/models"
import UserModel from "stores/models/user.models"

export const TextEditor = (props: any) => {
  const { data, onChange } = props
  const user: UserModel | undefined = useSelector((state: ReduxState) => state.users.user)

  const onChangeText = (event: any, editor: any) => {
    const data = editor.getData();
    onChange(data)
  }

  return (
    <CKEditor
      editor={ ClassicEditor }
      data={data}
      onChange={onChangeText}
      config={{
        language: user?.language ?? 'en',
        height: '600px',
        toolbar: {
          items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'link', 'blockQuote', '|', 'undo', 'redo']
        }
      }}
    />
  )
}
