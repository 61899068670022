import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  notAuthorizedWrapper: {
    paddingTop: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  notAuthorizedNum: {
    color: '#fd7e14',
    fontSize: '80px',
    textAlign: 'center'
  },

  notAuthorizedText: {
    color: '#666',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px',
  },

  btnYes: {
    border: '1px solid #2196F3',
    borderRadius: '5px',
    padding: '2px 20px',
    backgroundColor: '#2196F3',
    color: 'white',
    fontWeight: '600',
    marginTop: '10px',
  }
})

export default useStyles
