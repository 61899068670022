import { all } from 'redux-saga/effects'

// DEPLOY LOGS
import getDeployLogs from './deploy-logs/getDeployLogs.saga'
// USERS
import getUserActions from './users/getUserActions.saga'

function * rootSagas () {
  yield all([
    getDeployLogs(),

    getUserActions()
  ])
}

export default rootSagas
