// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import ReactPaginate from 'react-paginate'
import { US, NO } from 'country-flag-icons/react/3x2'

import { ReduxState } from 'stores/models'
import DeployLogModel, { DeployLogSource, DeployLogSources, DeployLogSourceText } from 'stores/models/deploy-log.models'
import { Wrapper } from 'components'

import useStyles from './styles'
import DeployLogItem from './components/deploy-log-item'
import { UserActions } from 'stores/models/user.models'
import { useNavigate } from 'react-router-dom'
import { localStorageUtils } from 'utils'
import { LocalStorageValueTypes } from 'utils/localStorage'
import { AppAPI } from 'stores/apis'
import './pagination.css'

interface FilterProps {
  t: any
  item: DeployLogSource | 0
  isActive: boolean
  onChangeFilterTab: (val: number) => void
}

const FilterTab = React.memo((props: FilterProps) => {
  const { t, item, isActive, onChangeFilterTab } = props
  const styles = useStyles()
  const onClick = () => onChangeFilterTab(item)

  return (
    <button onClick={onClick} className={isActive ? styles.filterTabActive : styles.filterTab}>
      <p className={isActive ? styles.filterTabTextActive : styles.filterTabText}>{item === 0 ? t('home.all') : DeployLogSourceText[item]}</p>
    </button>
  )
})

const Home = () => {
  const dispatch = useDispatch()
  const { t, i18n }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10
  const { user, isLoading, deployLogs, total, actions } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    isLoading: state.deployLogs.isLoading,
    deployLogs: state.deployLogs.deployLogs,
    total: state.deployLogs.total,
    actions: state.users.actions
  }))

  const [state, setState] = useState({
    filterTab: 0,
    page: 0,
    limit,
    showLangOptions: false,
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'GET_DEPLOY_LOGS_REQUEST',
        payload: {
          page: state.page,
          limit: state.limit,
          source: state.filterTab > 0 ? state.filterTab : undefined
        }
      })
    }
  }, [state.filterTab, state.page, user])

  const onChangeLanguage = (lang: string) => {
    handleStateChange({ showLangOptions: false })
    i18next.changeLanguage(lang)
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, lang)
    if (user) {
      AppAPI.changeLanguage({
        UserId: user.id,
        Language: lang
      })
    }
  }
  const onChangeFilterTab = (tab: number) => handleStateChange({ filterTab: tab, page: 0 })
  const onAddLog = () => navigate(`/logs/add`)

  const onPageChange = (e: any) => handleStateChange({ page: e.selected })
  const onToggleLanguageOption = () => handleStateChange({ showLangOptions: !state.showLangOptions })

  const renderDeployLog = (item: DeployLogModel) => <DeployLogItem key={item?.id} item={item} i18n={i18n} />
  const renderFilterTab = (item: number) => <FilterTab key={item} t={t} item={item} isActive={item === state.filterTab} onChangeFilterTab={onChangeFilterTab} />

  return (
    <Wrapper className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('home.title')}</h2>

        <div>
          <button onClick={onToggleLanguageOption} className={styles.lang}>
            {i18n.language === 'en' ? (
              <>
                <US title={t('home.english')} className={styles.flag} />
                <p>{t('home.english')}</p>
              </>
            ) : (
              <>
                <NO title={t('home.norway')} className={styles.flag} />
                <p>{t('home.norway')}</p>
              </>
            )}
            
          </button>
          {state.showLangOptions && (
            <ul className={styles.langList}>
              <li>
                <button className={styles.langRow} onClick={() => onChangeLanguage('en')}>
                  <US title={t('home.english')} className={styles.flag} />
                  {t('home.english')}
                </button>
              </li>
              <li>
                <button className={styles.langRow} onClick={() => onChangeLanguage('no')}>
                  <NO title={t('home.norway')} className={styles.flag} />
                  {t('home.norway')}
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>

      {user && actions.includes(UserActions.DeployLogAdd)
        && <button onClick={onAddLog} className={styles.btnAddLog}>{t('home.addLog')}</button>}

      <div className={styles.filters}>
        {[0, ...DeployLogSources].map(renderFilterTab)}
      </div>

      {isLoading
        ? <p>{t('home.loading')}</p>
        : total > 0 
          ? deployLogs.map(renderDeployLog)
          : <p className={styles.txtEmpty}>{t('home.noDeployLog')}</p>}


      {total > limit && (
        <div className="lc-page-wrapper">
          <ReactPaginate
            breakLabel="..."
            previousLabel="<"
            nextLabel=">"
            onPageChange={onPageChange}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(total / limit)}
            renderOnZeroPageCount={undefined}
            breakClassName="lc-page-break"
            pageClassName="lc-page-item"
            pageLinkClassName="lc-page-link"
            previousClassName="lc-page-item"
            previousLinkClassName="lc-page-link"
            nextClassName="lc-page-item"
            nextLinkClassName="lc-page-link"
            activeClassName="lc-page-active"
            disabledClassName="lc-page-disabled"
          />
        </div>
      )}
    </Wrapper>
  )
}

export default Home
