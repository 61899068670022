import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },

  txtName: {
    color: '#2196f3',
    fontWeight: 'bold',
    fontSize: '18px',
  },

  btnLogout: {
    marginLeft: '15px',
    border: '1px solid #dc3545',
    borderRadius: '20px',
    padding: '2px 15px',
    backgroundColor: 'white',
    color: '#dc3545',
    fontSize: '14px'
  },

  logoutBtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '20px',
  },

  btnNo: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '2px 20px',
    backgroundColor: '#ccc',
    fontWeight: '600'
  },

  btnYes: {
    border: '1px solid #dc3545',
    borderRadius: '5px',
    padding: '2px 20px',
    backgroundColor: '#dc3545',
    color: 'white',
    fontWeight: '600'
  }
})

export default useStyles
