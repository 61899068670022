import Modal from 'react-modal'
import { forwardRef, useImperativeHandle, useState } from "react"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const ModalComponent = forwardRef((props: any, ref: any) => {
  const [modal, setModal] = useState(undefined)

  const onCloseModal = () => setModal(undefined)

  useImperativeHandle(ref, () => ({
    open: (content: any) => {
      setModal(content)
    },
    close: () => {
      setModal(undefined)
    }
  }))

  return (
    <Modal
      isOpen={!!modal}
      onRequestClose={onCloseModal}
      contentLabel="Modal"
      style={customStyles}
    >
      {modal}
    </Modal>
  )
})

export default ModalComponent
