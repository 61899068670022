// get query string to object
export const getJsonFromUrl = (url: string) => {
  if (!url) url = window.location.search;
  const query = url.slice(1);
  const result: any = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}