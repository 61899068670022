import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    flexDirection: 'column',
    width: '70%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px'
  },

  title: {
    fontSize: '30px'
  },

  txtNote: {
    color: '#dc3545',
    fontSize: '13px',
    fontStyle: 'italic'
  },

  label: {
    fontSize: '16px',
    paddingTop: '20px',
    fontWeight: '500',
    marginBottom: '5px',
  },

  inputPassword: {
    height: '40px',
    border: '1px solid #c4c4c4',
    borderRadius: '5px'
  },

  buttonSave: {
    width: '150px',
    height: '40px',
    backgroundColor: '#2196F3',
    border: '0px transparent',
    borderRadius: '5px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: '20px',
  },

  buttonSaveDisabled: {
    marginTop: '20px',
    width: '150px',
    height: '40px',
    border: '0px transparent',
    borderRadius: '5px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    backgroundColor: '#E0E0E0',
  }
})

export default useStyles
